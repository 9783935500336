exports.onInitialClientRender = () => {
  var p = "https://www.sevenrooms.com/widget/embed.js"
  var d = document
  var s = d.createElement("script")
  s.src = p
  ;(d.head || d.body).appendChild(s)
  s.onload = function () {
    if (document.getElementById("sr-res-root")) {
      window.SevenroomsWidget.init({
        venueId: "sumi",
        triggerId: "sr-res-root", // id of the dom element that will trigger this widget
        type: "reservations", // either 'reservations' or 'waitlist' or 'events'
        styleButton: true, // true if you are using the SevenRooms button
      })
    }
    if (document.getElementById("sr-res-root-menu")) {
      window.SevenroomsWidget.init({
        venueId: "sumi",
        triggerId: "sr-res-root-menu", // id of the dom element that will trigger this widget
        type: "reservations", // either 'reservations' or 'waitlist' or 'events'
        styleButton: false, // true if you are using the SevenRooms button
      })
    }
  }
}
